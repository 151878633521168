import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233'),
	() => import('./nodes/234'),
	() => import('./nodes/235'),
	() => import('./nodes/236'),
	() => import('./nodes/237'),
	() => import('./nodes/238'),
	() => import('./nodes/239'),
	() => import('./nodes/240')
];

export const server_loads = [0,28,24,8,25,9,10,4,6];

export const dictionary = {
		"/(marketing)": [175,[18]],
		"/(marketing)/about": [176,[18,19]],
		"/(admin)/admin": [36,[2]],
		"/(admin)/admin/abundant/add-update": [~37,[2]],
		"/(admin)/admin/abundant/duplicate-risk-type": [~38,[2]],
		"/(admin)/admin/abundant/evict-file-cache": [~39,[2]],
		"/(admin)/admin/abundant/groups": [~40,[2]],
		"/(admin)/admin/abundant/migrate-db": [~41,[2]],
		"/(admin)/admin/abundant/open-api": [42,[2]],
		"/(admin)/admin/abundant/queries-sg": [~43,[2]],
		"/(admin)/admin/abundant/reset-db": [~44,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-db": [~45,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-disk": [~46,[2]],
		"/(admin)/admin/abundant/sync-validation-to-db": [~47,[2]],
		"/(admin)/admin/abundant/sync-validation-to-disk": [~48,[2]],
		"/(admin)/admin/categories/add": [~49,[2]],
		"/(admin)/admin/charts/bar": [~50,[2]],
		"/(admin)/admin/dev-ops/analytics.calendar-month": [~51,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-full-load": [~52,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-incremental-load": [~53,[2]],
		"/(admin)/admin/dev-ops/find-unused-files": [~54,[2]],
		"/(admin)/admin/dev-ops/run-pipelines": [~55,[2]],
		"/(admin)/admin/misc/add-analytics-board": [~56,[2]],
		"/(admin)/admin/misc/create-thumbnails-by-id": [~57,[2]],
		"/(admin)/admin/misc/customer-scripts": [~58,[2]],
		"/(admin)/admin/misc/get-asset-info": [~59,[2]],
		"/(admin)/admin/misc/inspect-devalue": [60,[2]],
		"/(admin)/admin/misc/sendgrid-delete-contacts": [~61,[2]],
		"/(admin)/admin/risk-data/copy-risk-model-to-prod": [~62,[2]],
		"/(admin)/admin/risk-data/copy-risk-type": [~63,[2]],
		"/(admin)/admin/test-email": [~64,[2]],
		"/(admin)/admin/users/assign-permissions": [~65,[2]],
		"/(admin)/admin/users/delete": [~66,[2]],
		"/(admin)/admin/users/end-session": [~67,[2]],
		"/(admin)/admin/users/seed": [~68,[2]],
		"/(admin)/admin/users/set-user-tasks": [~69,[2]],
		"/(admin)/admin/users/toggle-21risk-admin": [~70,[2]],
		"/(marketing)/blog": [177,[18,20]],
		"/(marketing)/blog/[slug]": [178,[18,20]],
		"/(marketing)/changelog": [179,[18]],
		"/(marketing)/changelog/[slug]": [180,[18]],
		"/(marketing)/checklists": [~181,[18]],
		"/(marketing)/checklists/success": [185,[18]],
		"/(marketing)/checklists/view": [186,[18]],
		"/(marketing)/checklists/[_id=objectId]": [~182,[18]],
		"/(marketing)/checklists/[_id=objectId]/print": [~183,[18]],
		"/(marketing)/checklists/[_id=objectId]/request": [~184,[18]],
		"/(docs)/compliance": [169,[17]],
		"/(docs)/compliance/[...rest]": [~170,[17]],
		"/(marketing)/contact": [187,[18]],
		"/(marketing)/contact/sales": [~188,[18]],
		"/(marketing)/contact/sales/success": [189,[18]],
		"/(marketing)/customers": [190,[18,21]],
		"/(marketing)/customers/BHJ": [191,[18,21]],
		"/(marketing)/customers/[slug]": [192,[18,21]],
		"/(docs)/docs": [171,[17]],
		"/(docs)/docs/concepts": [173,[17]],
		"/(docs)/docs/concepts/[...rest]": [174,[17]],
		"/(docs)/docs/[...rest]": [~172,[17]],
		"/(reset)/emails/preview/[templateId]/[sendgridPayload]": [~224,[28]],
		"/(marketing)/experts": [193,[18,22]],
		"/(marketing)/experts/[slug]": [~194,[18,22,23]],
		"/(marketing)/experts/[slug]/success": [195,[18,22,23]],
		"/(marketing)/features/actions": [196,[18]],
		"/(marketing)/features/audits": [197,[18]],
		"/(unauthed)/feedback/emails/thank-you": [231,[30,31]],
		"/(unauthed)/feedback/emails/[emailId]": [~230,[30,31]],
		"/(marketing)/help": [~198,[18,24]],
		"/(marketing)/help/success": [199,[18,24]],
		"/(marketing)/legal": [200,[18]],
		"/(marketing)/legal/[slug]": [201,[18]],
		"/(unauthed)/library-public": [232,[30]],
		"/(unauthed-prerender)/login": [~236,[32,33]],
		"/(unauthed-prerender)/login/check": [~237,[32,33]],
		"/(authed)/logout": [~168,[8]],
		"/(admin)/mgmt": [71,[3]],
		"/(admin)/mgmt/analytics/overview": [~72,[3]],
		"/(unauthed)/misc/debug": [233,[30]],
		"/(unauthed)/misc/files-list": [~234,[30]],
		"/(marketing)/newsletter/check": [202,[18]],
		"/(marketing)/newsletter/subscribe": [~203,[18]],
		"/(marketing)/newsletter/success": [204,[18]],
		"/(marketing)/newsletter/verify": [~205,[18]],
		"/(unauthed)/no-access/[tenant_without_access=org_slug]": [~235,[30]],
		"/(reset)/onboarding": [225,[28,29]],
		"/(reset)/onboarding/1": [226,[28,29]],
		"/(reset)/onboarding/2": [~227,[28,29]],
		"/(reset)/onboarding/3": [~228,[28,29]],
		"/(reset)/onboarding/4": [229,[28,29]],
		"/(marketing)/partners": [~206,[18]],
		"/(marketing)/partners/success": [207,[18]],
		"/(marketing)/pricing": [208,[18]],
		"/(marketing)/roadmap": [209,[18]],
		"/(unauthed-prerender)/signup": [~238,[32,34]],
		"/(unauthed-prerender)/signup/check": [~239,[32,34]],
		"/(unauthed-prerender)/signup/complete": [~240,[32,34]],
		"/(marketing)/solutions/compliance": [210,[18]],
		"/(marketing)/solutions/insurance-values": [211,[18]],
		"/(marketing)/solutions/natcat": [212,[18]],
		"/(marketing)/solutions/natcat/[hazard]": [~213,[18]],
		"/(marketing)/solutions/property-insurance": [~214,[18]],
		"/(marketing)/solutions/property-insurance/roi-breakdown": [~215,[18]],
		"/(marketing)/solutions/property-insurance/roi-breakdown/success": [216,[18]],
		"/(marketing)/solutions/property-insurance/roi-calculator": [~217,[18]],
		"/(marketing)/updates": [218,[18,25]],
		"/(marketing)/updates/[slug]": [219,[18,25]],
		"/(marketing)/whitepapers": [220,[18,26]],
		"/(marketing)/whitepapers/success": [222,[18,26]],
		"/(marketing)/whitepapers/view/[uuid]": [223,[18,26]],
		"/(marketing)/whitepapers/[slug]": [~221,[18,26,27]],
		"/(authed)/[tenant=org_slug]/actions": [~99,[8,9,10]],
		"/(authed)/[tenant=org_slug]/actions/board": [101,[8,9,10]],
		"/(authed)/[tenant=org_slug]/actions/table": [~102,[8,9,10]],
		"/(authed)/[tenant=org_slug]/actions/[view]": [100,[8,9,10]],
		"/(authed)/[tenant=org_slug]/action/[itemId]": [~98,[8,9]],
		"/(app)/[tenant=org_slug]/analytics/[_id]": [73,[4]],
		"/(authed)/[tenant=org_slug]/automations/actions": [~103,[8,9,11]],
		"/(authed)/[tenant=org_slug]/automations/actions/new": [~105,[8,9,11]],
		"/(authed)/[tenant=org_slug]/automations/actions/[_id=objectId]": [~104,[8,9,11]],
		"/(authed)/[tenant=org_slug]/automations/models/[riskModelId=objectId]": [~106,[8,9,11]],
		"/(authed)/[tenant=org_slug]/automations/models/[riskModelId=objectId]/new": [~108,[8,9,11]],
		"/(authed)/[tenant=org_slug]/automations/models/[riskModelId=objectId]/[_id=objectId]": [~107,[8,9,11]],
		"/(authed)/[tenant=org_slug]/a/[reportId]/[[page]]": [~97,[8,9]],
		"/(app)/[tenant=org_slug]/board": [74,[4]],
		"/(app)/[tenant=org_slug]/board/[boardId]": [75,[4,5]],
		"/(app)/[tenant=org_slug]/board/[boardId]/actions": [76,[4,5,6]],
		"/(app)/[tenant=org_slug]/board/[boardId]/actions/table": [78,[4,5,6]],
		"/(app)/[tenant=org_slug]/board/[boardId]/actions/[view]": [77,[4,5,6]],
		"/(app)/[tenant=org_slug]/board/[boardId]/edit": [~79,[4,5]],
		"/(app)/[tenant=org_slug]/board/[boardId]/reports": [80,[4,5]],
		"/(authed)/[tenant=org_slug]/dashboard": [~109,[8,9]],
		"/(authed)/[tenant=org_slug]/dashboard/empty": [112,[8,9]],
		"/(authed)/[tenant=org_slug]/dashboard/[riskTypeId=objectId]": [~111,[8,9]],
		"/(authed)/[tenant=org_slug]/dashboard/[...rest]": [110,[8,9]],
		"/(authed)/[tenant=org_slug]/debug": [113,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/config/print-values": [114,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-10": [117,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-11": [118,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-12": [119,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-1": [~116,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-2": [~120,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-30": [~122,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-31": [~123,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-32": [~124,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-3": [121,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-5": [~125,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/errors/[...0]intro": [115,[8,9,12]],
		"/(authed)/[tenant=org_slug]/debug/forms/[...0]intro": [126,[8,9,12]],
		"/(authed)/[tenant=org_slug]/file-upload": [~127,[8,9]],
		"/(app)/[tenant=org_slug]/folder/[_id]": [81,[4]],
		"/(app)/[tenant=org_slug]/getting-started": [~82,[4]],
		"/(authed)/[tenant=org_slug]/insurance/get-started": [129,[8,9]],
		"/(authed)/[tenant=org_slug]/insurance/[[page]]": [128,[8,9]],
		"/(authed)/[tenant=org_slug]/landing-page": [130,[8,9]],
		"/(authed)/[tenant=org_slug]/library": [~131,[8,9]],
		"/(authed)/[tenant=org_slug]/library/edit/[_id=objectId]": [~133,[8,9]],
		"/(authed)/[tenant=org_slug]/library/new": [~134,[8,9]],
		"/(authed)/[tenant=org_slug]/library/[_id=objectId]": [~132,[8,9]],
		"/(authed)/[tenant=org_slug]/manage-data": [~135,[8,9,13]],
		"/(authed)/[tenant=org_slug]/manage-data/locations": [~136,[8,9,13,14]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/import": [~138,[8,9,13,14]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/[siteId]": [~137,[8,9,13,14]],
		"/(authed)/[tenant=org_slug]/manage-data/tags": [~139,[8,9,13]],
		"/(authed)/[tenant=org_slug]/manage-data/tags/[tagId]": [~140,[8,9,13]],
		"/(authed)/[tenant=org_slug]/members": [~141,[8,9]],
		"/(authed)/[tenant=org_slug]/members/new": [~143,[8,9]],
		"/(authed)/[tenant=org_slug]/members/[user_id]": [~142,[8,9]],
		"/(authed)/[tenant=org_slug]/models": [144,[8,9]],
		"/(app)/[tenant=org_slug]/new/board": [~83,[4]],
		"/(app)/[tenant=org_slug]/new/folder": [~84,[4]],
		"/(authed)/[tenant=org_slug]/profile": [~145,[8,9,15]],
		"/(authed)/[tenant=org_slug]/profile/account": [~146,[8,9,15]],
		"/(authed)/[tenant=org_slug]/profile/account/balance-changes": [~147,[8,9,15]],
		"/(authed)/[tenant=org_slug]/profile/account/roles/[roleId]": [~148,[8,9,15]],
		"/(authed)/[tenant=org_slug]/profile/billing": [149,[8,9,15]],
		"/(authed)/[tenant=org_slug]/profile/integrations": [150,[8,9,15]],
		"/(authed)/[tenant=org_slug]/profile/notifications": [151,[8,9,15]],
		"/(authed)/[tenant=org_slug]/reports/a": [~160,[8,9]],
		"/(authed)/[tenant=org_slug]/reports/request-audit": [~161,[8,9]],
		"/(authed)/[tenant=org_slug]/reports/[[view]]": [~159,[8,9,16]],
		"/(authed)/[tenant=org_slug]/report/new": [~155,[8,9]],
		"/(authed)/[tenant=org_slug]/report/new/[riskType=objectId]": [~156,[8,9]],
		"/(authed)/[tenant=org_slug]/report/new/[riskType=objectId]/[site=objectId]": [~157,[8,9]],
		"/(authed)/[tenant=org_slug]/report/start/[_id=objectId]": [~158,[8,9]],
		"/(authed)/[tenant=org_slug]/report/[_id=objectId]": [~152,[8,9]],
		"/(authed)/[tenant=org_slug]/report/[_id=objectId]/print": [~153,[8,9]],
		"/(authed)/[tenant=org_slug]/report/[_id=objectId]/qr-code": [~154,[8,9]],
		"/(authed)/[tenant=org_slug]/risk-reports": [162,[8,9]],
		"/(authed)/[tenant=org_slug]/risk-reports/[_id]": [163,[8,9]],
		"/(app)/[tenant=org_slug]/settings/account": [~85,[4,7]],
		"/(app)/[tenant=org_slug]/settings/account/roles/[roleId]": [~86,[4,7]],
		"/(app)/[tenant=org_slug]/settings/profile": [~87,[4,7]],
		"/(app)/[tenant=org_slug]/settings/sites": [~88,[4,7]],
		"/(app)/[tenant=org_slug]/settings/sites/import": [~90,[4,7]],
		"/(app)/[tenant=org_slug]/settings/sites/[siteId=objectId]": [~89,[4,7]],
		"/(app)/[tenant=org_slug]/settings/tags": [~91,[4,7]],
		"/(app)/[tenant=org_slug]/settings/tags/[tagId=objectId]": [~92,[4,7]],
		"/(app)/[tenant=org_slug]/settings/users": [~93,[4,7]],
		"/(app)/[tenant=org_slug]/settings/users/new": [~95,[4,7]],
		"/(app)/[tenant=org_slug]/settings/users/[user_id]": [~94,[4,7]],
		"/(authed)/[tenant=org_slug]/sign-in": [164,[8,9]],
		"/(authed)/[tenant=org_slug]/support": [165,[8,9]],
		"/(authed)/[tenant=org_slug]/test-error-load": [167,[8,9]],
		"/(authed)/[tenant=org_slug]/test-error": [166,[8,9]],
		"/(authed)/[...rest]": [96,[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';